import React, {useState, useEffect} from "react"
import {Container,Row,Col,Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";

import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Preval_Presentation"; 
import {config} from '../../components/Common/constant';
import Valuation_Day from '../../components/modules/valuation_day';

import { GET_ARTICLE } from "../../queries/common_use_query";

const ValuationDay = () => {
  const [ article_data, setArticleData ] = useState('');
  const { loading, error, data } = GET_ARTICLE('valuation-day-agenda');

  useEffect(()=>{
      setArticleData(data && data.articles[0]);
  }, [data]);

  return(
    <>
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

    <div className="pre-wrapper">
        <Header />

        <Valuation_Day />

        <NavLink tab_name="Valuation Day" prevLink={config.preval_marketing}  nextLink={config.preval_contact_us} />
    </div>
    </>
  )

}

export default ValuationDay