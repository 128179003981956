import React, {useState, useEffect} from "react"
import {Container,Row,Col,Button} from 'react-bootstrap';
import { Link, navigate } from "gatsby";

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE } from "../../queries/common_use_query";

import TimeTrackerComp from "./time_tracker";

const ValuationDay = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('valuation-day-agenda');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    return(
        <>
            <TimeTrackerComp TabName={'ValuationDay'} />

            <div className="section-wrapper">
                <Container>
                    <h2>{article_data && article_data.Title}</h2>

                    {article_data && article_data.Content && <p className="fig-content mb_cnt font_20">{HTMLReactParser(article_data.Content)}</p>}

                    <ul className="valDayList">
                        {
                            article_data && article_data.Modules.map((valuation, index) => {
                                return(
                                    <li>
                                        <div className="ValDayImg">
                                            { valuation.Upload_Image ? <img src={strapi_url+''+valuation.Upload_Image.url} alt="" /> : (
                                                <span className="coming_soon">Image <br />Coming Soon</span>
                                            ) }
                                        </div>
                                        
                                        <div className="ValDayText">
                                            <h4>{valuation.Title}</h4>
                                            <p className="fig-content font_18">{valuation.Content && HTMLReactParser(valuation.Content)}</p>
                                        </div>                        
                                    </li>
                                )
                            })
                        }

                    </ul>
                </Container>
            </div>
        </>
    )

}

export default ValuationDay